.container{
    min-height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color_dark_1);
}
.wrapper{
    display: flex;
    flex-direction: column;

    width:calc(35 * var(--basex));
}

.split{
    display: flex;
    justify-content: space-between;

    margin-bottom: 2rem;
}
.split > div {
    width:47%;
}
.cta{
    display: flex;
    align-items: center;
}
.cta > div{
    width: fit-content;
}
.cta > div:not(:last-child){
    margin-right: 2.4rem;
}
.documentBox{
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--color_dark_1);
    padding: calc(1 * var(--basey)) calc(1 * var(--basex));
}
.filename{
    margin-bottom: calc(1 * var(--basey));
    color: var(--color_dark_1);
    font-size: calc(1 * var(--basex));
}
.documentBox:not(:last-child){
    margin-bottom: calc(1 * var(--basey));
}
.documentBox svg{
    color: var(--color_dark_1);
}
.documentBox > div{
    display: flex;
    align-items: center;
}
.documentBox > div:nth-child(1){
    width: 80%;
}
.documentBox > div:nth-child(2) svg{
    font-size: 2rem;
}
.documentBox > div:nth-child(3) svg{
    font-size: 3rem;
}
.documentBox > div:nth-child(2),.documentBox > div:nth-child(3){
    width: 10%;
}

