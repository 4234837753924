.form{
    width: calc(50 * var(--basex));
    padding: calc(4 * var(--basey)) 0;
}
.form > div:not(:last-child){
    margin-bottom: calc(1.5 * var(--basey));
}
.form > div:last-child > div{
    width: fit-content;
}

@media screen and (max-width:700px) {
    .form{
        width: 100%;
    }
}

