.container{
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    background-color: white;

    z-index: 100;
}
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--color_dark_1);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
    margin-bottom: calc(2 * var(--basey));
  }
  .loader:after {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    border: 5px solid var(--color_dark_1);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
  }
  
  @keyframes scaleUp {
    0% { transform: translate(-50%, -50%) scale(0) }
    60% , 100% { transform: translate(-50%, -50%)  scale(1)}
  }
  @keyframes pulse {
    0% , 60% , 100%{ transform:  scale(1) }
    80% { transform:  scale(1.2)}
  }