.container{
    width: 31.5rem;
    height: 100vh;
    overflow: hidden;

    background-color: var(--color_light_1);

    display: flex;
    flex-direction: column;
}
.collapse{
    width: 7rem !important;
}
.hide{
    display: none;
    visibility: hidden;
    transition: all 0 !important;
}
.cta{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: calc(1 * var(--basey)) 0;
    padding-right: calc(2 * var(--basex));  
}
.cta svg{
    font-size: 3rem;
    color: var(--color_dark_1);
    cursor: pointer;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height:21.5%;
}
.logo > div{
    /* background-color: var(--color_dark_1); */
}

.logo img{
    height: calc(12 * var(--basey));
}
.nav{
    flex: 1;
}
.logout{
    width: 100%;
    height: 12%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.logout div{
    width: 100%;
    display: flex;
    align-items: center;

    padding: calc(1.6 * var(--basey)) 0;
    padding-left: calc(5.04 * var(--basex));  
    font-size: 1.92rem; 
    background-color: var(--color_dark_1);
    color: var(--color_light_1);
}
.logout div svg{
    margin-right: calc(1.92 * var(--basex));
    font-size: 3.2rem;
}

@media screen and (max-width:700px) {
    .container{
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
    }
    .collapse{
        height: 5vh;
    }
}