.container{
    width: 100%;

    padding: calc(2.2 * var(--basey)) calc(2.5 * var(--basex)) calc(2.2 * var(--basey)) calc(2.5 * var(--basey));
    display: flex;
    flex-direction: column;

    color: var(--color_light_1);
    background-color: var(--color_dark_1);

    border-radius: 5px;
}

.container div:nth-child(1){
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
}
.container div:nth-child(2){
    font-size: 2.72rem;
}