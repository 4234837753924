.container{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.container > div {
    display: flex;
}



.left{
    width: calc(50 * var(--basex));
    padding: calc(6 * var(--basey)) calc(4 * var(--basex)) calc(6 * var(--basey)) calc(6 * var(--basex));
    height: 100vh;
    overflow-x: auto;
    scrollbar-width: thin;
    background-color: var(--color_dark_1);
    color:white;
}
.left > div > div:first-child > div > img{
    width: calc(17.5 * var(--basex));
    margin-bottom: calc(4 * var(--basey));
}
.left > div > div:nth-child(2){
    margin-bottom: calc(1.5 * var(--basey));
}
.left > div > div:nth-child(3){
    margin-bottom: calc(3 * var(--basey));
}
.split{
    display: flex;
    justify-content: space-between;

    margin-bottom: 2rem;
}
.split > div {
    width:47%;
}
.documentBox{
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid white;
    padding: calc(1 * var(--basey)) calc(1 * var(--basex));
}
.filename{
    margin-bottom: calc(1 * var(--basey));
    color:white;
    font-size: calc(1 * var(--basex));
}
.documentBox svg{
    color:white;
}
.documentBox > div{
    display: flex;
    align-items: center;
}
.documentBox > div:nth-child(1){
    width: 90%;
}
.documentBox > div:nth-child(2) svg{
    font-size: 2rem;
}

.documentBox > div:nth-child(2){
    width: 10%;
}
.cta{
    display: flex;
    align-items: center;
}
.cta > div{
    width: fit-content;
}
.cta > div:not(:last-child){
    margin-right: 2.4rem;
}


.right{
    height: 100vh;
    overflow: hidden;
    flex:1;
    background-image: linear-gradient(to right,rgba(10, 36, 42 ,.5),rgba(10, 36, 42 ,.5)) ,url("./../../../../public/residence.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding: calc(4 * var(--basey)) calc(4 * var(--basex));

    color: var(--color_light_1);
}

@media only screen and (max-width:700px){
    .left{
        width: 100%;
        padding: calc(3 * var(--basey)) calc(2 * var(--basex)) calc(3 * var(--basey)) calc(3 * var(--basex));
    }
    .right{
        width: 0%;
        display: none;
        visibility: hidden;
    }
    .split {
        
    }
}