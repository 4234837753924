.container{
    width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
}
.filters{
    width:100%;

    display: flex;
    align-items: center;
    padding: calc(5 * var(--basey)) calc(3.4 * var(--basex));
    border-bottom: 1px solid var(--color_dark_1);
}
.input{
    width: 50%;
    display: flex;
    align-items: center;

    position: relative;
}
.input input{
    margin-bottom: 0;
}
.search{
    display: flex;
    align-items: center;
}
.search svg{
    font-size: 2.6rem;
    color: var(--color_dark_1);
    margin-left: -200%;
}

.btns{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.btns > div{
    margin-left: calc(2 * var(--basex));
}

.data{
    flex: 1;
}

.head{
    padding: calc(3.8 * var(--basey)) calc(3.4 * var(--basex)) calc(7 * var(--basey)) calc(3.4 * var(--basex));

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content{
    padding: calc(0 * var(--basey)) calc(3.4 * var(--basex)) calc(0 * var(--basey)) calc(3.4 * var(--basex));
}
.top{
    width: 100%;
    display: flex;
    margin-bottom: calc(2 * var(--basey));
}
.top div:nth-child(1),.top div:nth-child(2),.top div:nth-child(3){
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row{
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(4.8* var(--basey));
    background-color: var(--color_light_1);
    margin-bottom: calc(1 * var(--basey));
}
.row div:nth-child(1),.row div:nth-child(2),.row div:nth-child(3){
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.row div:nth-child(4),.row div:nth-child(5){
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row div:nth-child(6){
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cta{
    color: var(--color_dark_1);
    font-weight: 500;

    cursor: pointer;
}
.cta svg{
    font-size: 2rem;
}

.card{
    position: relative;
    padding: calc(2 * var(--basey)) calc(1 * var(--basex));

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--color_light_1);
    margin-bottom: calc(2 * var(--basey));
}

.card > div{
    width: 100%;
    display: flex;
    align-items: center;
}

.card > div:first-child{
    width: fit-content;
    position: absolute;
    top: calc(1.5 * var(--basey));
    right: calc(1.5 * var(--basex));
}
.card > div:not(:first-child,:last-child) {
    margin-bottom: calc(2 * var(--basey))
}
.card > div:not(:first-child) > div{
    width: 49%;
    margin-left: 1%;
}
@media screen and (max-width:700px) {
    .filters{
        flex-direction: column;
    }
    .input{
        width: 100%;

        margin-bottom: calc(2 * var(--basey));
    }
    .search svg{
        margin-left: 0%;

        position: absolute;
        right: 2%;
    }
    .btns{
        width: 100%;
    }
}