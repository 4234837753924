.container{
    padding: calc(5 * var(--basey)) calc(3.4 * var(--basex));
    background-color: var(--color_light_1);

    min-height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
}

.wrapper{
    display: flex;
    flex-direction: column;
} 

.wrapper > div:first-child svg{
    font-size: 4rem;
}
.wrapper > div:not(:last-child) {
    margin-bottom: calc(1 * var(--basey));
}
.nav{
    width: 100%;
    overflow-x: auto;

    border-bottom: 1px solid var(--color_dark_1);
}
.nav > div{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: calc(80 * var(--basex));
}
.nav > div > span{
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    height: calc(4.8* var(--basey));

}
.active{
    background-color: var(--color_dark_1);
    color: var(--color_light_1);
}

/* .form{
    width: calc(50 * var(--basex));
    padding: calc(4 * var(--basey)) 0;
}
.form > div:not(:last-child){
    margin-bottom: calc(1.5 * var(--basey));
}
.form > div:last-child > div{
    width: fit-content;
} */