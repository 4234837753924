.container{
    /* width: 100%; */
    height: calc(4.8* var(--basey));

    padding: 0 calc(1.7 * var(--basex));
    border: 2px solid black;
    border-radius: 5px;


    display: flex;
    align-items: center;
    position: relative;
    background-color: white;

}

.label{
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.label div{
    margin-left: calc(1 * var(--basex));
}
.label div svg{
    display: flex;
    justify-content: center;
    align-items: center;
}

.options{
    position: absolute;
    width: 100%;
    top:calc(100% + var(--basey));
    right: 0;
    background-color: white;
    border-radius: 5px;

    z-index: 1;
}
.option{
    display: flex;
    align-items: center;
    cursor: pointer;
    
    padding:  calc(1 * var(--basey)) calc(1.7 * var(--basex)) calc(1 * var(--basey)) calc(.7 * var(--basex));
}

.option:hover{
    color: var(--color_light_1);
    background-color: var(--color_dark_1);
}

.option div{
    margin-left: calc(1 * var(--basex));
}