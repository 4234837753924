@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --color_light_1:#eeeeee;
  /* --color_dark_1:#D7242A; */
  --color_dark_1:#2c2c30;
  --color_dark_2:#393E46;

  --basex:10px;
  --basey:10px;
}
html{
  font-size: 10px;
  color: var(--color_dark_2);
  font-family: 'Poppins', sans-serif;
}
*,*::after,*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.6rem;

}
::-webkit-scrollbar{
  width: 5px;
}
::-webkit-scrollbar-track{
  background-color: var(--color_light_1);
}
::-webkit-scrollbar-thumb{
  background: var(--color_dark_1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media only screen and (max-width:1200px){
  :root{
    --basex:9px;
    --basey:9px;
  }
  html{
    font-size: 9px;
  }
}

@media only screen and (max-width:992px){
  :root{
    --basex:8px;
    --basey:8px;
  }
  html{
    font-size: 8px;
  }
  ::-webkit-scrollbar{
    width: 0px;
  }
}