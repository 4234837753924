.container{
    width: 100%;
    height: 100vh;
    overflow:hidden;
    display: flex;
}
@media screen and (max-width:700px) {
    .outlet{
        width: 100vw;
    }
}
