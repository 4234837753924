.btn{
    /* width: 122px; */
    height: calc(4.5 * var(--basey));
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color_light_1);
    background-color: var(--color_dark_1);

    padding: 0 calc(1.7 * var(--basex));
    cursor: pointer;
}