.form{
    width: calc(50 * var(--basex));
    padding: calc(4 * var(--basey)) 0;
}
.form > div:not(:last-child){
    margin-bottom: calc(1.5 * var(--basey));
}
.form > div:last-child > div{
    width: fit-content;
}
.email{
    display: flex;
    width: 100%;
}
.email > div:first-child{
    width: 80%;
    display: flex;
    align-items: center;
    position: relative;
}
.email > div:first-child > div{
    position: absolute;
    top: calc(1 * var(--basey));
    right: calc(1 * var(--basex));
    cursor: pointer;
}
.email > div:first-child > div > svg{
    font-size: 3rem;
}

.email > div:last-child{
    width: 20%;
    background-color: var(--color_dark_1);
    color: var(--color_light_1);
    height: calc(4.8* var(--basey));
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    width:100%;
    padding: calc(2 * var(--basey)) calc(2 * var(--basex));

    background-color: white;
    border-radius: calc(.5 * var(--basex));
    overflow: hidden;

    margin-bottom: calc(4 * var(--basey));
}
.card > div{
    display:flex;
    flex-direction: column;
}
.card > div > div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: calc(2 * var(--basey));
}

.collapse{
    height: calc(6 * var(--basey));
}
.split{
    display: flex;
    justify-content: space-between;

    margin-bottom: 2rem;
}
.split > div {
    width:47%;
    height: 100%;
}

.documentBox{
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--color_dark_1);
    padding: calc(1 * var(--basey)) calc(1 * var(--basex));
}
.filename{
    margin-bottom: calc(1 * var(--basey));
    color: var(--color_dark_1);
    font-size: calc(1 * var(--basex));
}
.documentBox svg{
    color: var(--color_dark_1);
}
.documentBox > div{
    display: flex;
    align-items: center;
}
.documentBox > div:nth-child(1){
    width: 80%;
}
.documentBox > div:nth-child(2) svg{
    font-size: 2rem;
}
.documentBox > div:nth-child(3) svg{
    font-size: 3rem;
}
.documentBox > div:nth-child(2),.documentBox > div:nth-child(3){
    width: 10%;
}
.cta{
    display: flex;
    align-items: center;
}
.cta > div{
    width: fit-content;
}
.cta > div:not(:last-child){
    margin-right: 2.4rem;
}
@media screen and (max-width:700px) {
    .form{
        width: 100%;
    }
}

