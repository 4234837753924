.container{
    position: fixed;

    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: rgba(0,0,0,.4);
    z-index: 99;
}
.wrapper{
    width:calc(40 * var(--basex));
    padding: calc(2 * var(--basey)) calc(4 * var(--basex));
    background-color: var(--color_light_1);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.wrapper > div{
    width: 100%;
}
.wrapper > div:first-child{
    margin-bottom: calc(2 * var(--basey));
}
.wrapper > div:last-child{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.wrapper > div:last-child >span{
    cursor: pointer;
}
.wrapper > div:last-child >span:first-child{
    background-color: var(--color_dark_1);
    color: #fff;

    padding: calc(1 * var(--basey));
}