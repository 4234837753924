.container{
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color_dark_1);
}
.wrapper{
    display: flex;
    flex-direction: column;

    width:calc(35 * var(--basex));
}

.split{
    display: flex;
    justify-content: space-between;

    margin-bottom: 2rem;
}
.split > div {
    width:47%;
}
.cta{
    display: flex;
    align-items: center;
}
.cta > div{
    width: fit-content;
}
.cta > div:not(:last-child){
    margin-right: 2.4rem;
}