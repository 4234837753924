.container{
    background-color: var(--color_light_1);
    padding: calc(4 * var(--basey)) calc(4 * var(--basex));
    border-radius: calc(2.5 * var(--basex));
    margin: calc(10 * var(--basey)) 0;
}

@media only screen and (max-width:600px){
    .container{
        /* min-height: 100vh; */
        height: 100%;
        width: 100%;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin:0 0; */
        margin-top: calc(1 * var(--basey));
        margin-bottom: calc(1 * var(--basey)) !important;

    }
  }