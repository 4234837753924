.container{
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}
.export{
    position: fixed;
    bottom: calc(3 * var(--basey));
    right: calc(2 * var(--basex));
    padding: calc(1 * var(--basex)) calc(1 * var(--basey));
    z-index: 10;
    color: var(--color_light_1);
    background-color: var(--color_dark_1);
    text-decoration: none;

    box-shadow: 0 calc( 1 * var(--basex)) calc(1.5 * var(--basex)) var(--color_dark_1);
}
.filters{
    width:100%;
    /* height: 30%; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(5 * var(--basey)) calc(3.4 * var(--basex));
    border-bottom: 1px solid var(--color_dark_1);
}
.input{
    width: 100%;
    /* display: flex; */
    /* align-items: center; */
}
.input:not(:last-child){
    margin-right:calc(1 * var(--basex));
}
.input input{
    margin-bottom: 0;
}
.search{
    display: flex;
    align-items: center;
}
.search svg{
    font-size: 2.6rem;
    color: var(--color_dark_1);
    margin-left: -200%;
}

.btns{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: calc(2.5 * var(--basey));
}
.btns > div{

    display: flex;
    align-items: center;
}
.btns > div:first-child{
    width: 50%;
}
.btns > div:last-child > div{
    margin-left: calc(2 * var(--basex));
}
.data{
    flex: 1;
}

.head{
    padding: calc(3.8 * var(--basey)) calc(3.4 * var(--basex)) calc(7 * var(--basey)) calc(3.4 * var(--basex));

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content{
   
    overflow-y: auto;
    padding: calc(0 * var(--basey)) calc(3.4 * var(--basex)) calc(0 * var(--basey)) calc(3.4 * var(--basex));
}
.top{
    width: 100%;
    display: flex;
    margin-bottom: calc(2 * var(--basey));
}
.top > div:nth-child(1),.top > div:nth-child(2),.top > div:nth-child(3),.top > div:nth-child(4){
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row{
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(4.8* var(--basey));
    background-color: var(--color_light_1);
    margin-bottom: calc(1 * var(--basey));
}
.row > div:nth-child(1),.row > div:nth-child(2),.row > div:nth-child(3),.row > div:nth-child(4){
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row > div:nth-child(5){
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row > div:nth-child(7){
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cta{
    color: var(--color_dark_1);
    font-weight: 500;
    cursor: pointer;
}
.cta svg{
    font-size: 2rem;
}
.advance_filters{
    height: calc(4.8* var(--basey));
    padding: 0 calc(1.7 * var(--basex));

    border: 2px solid var(--color_dark_1);
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color_dark_1);
}
.advance_filters svg{
    font-size: 3rem;
    margin-right: calc(1 * var(--basex));
}
.stats{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.stats > div{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 75%;
}
.stats > div > div{
    display: flex;
    align-items: center;
    width: 31.5%;
}
@media screen and (max-width:1300px) {
    .stats{
        flex-direction: column;
    }
    .stats > div{
        width: 100%;
        margin-bottom: calc(1 * var(--basey));
    }
}
.card{
    position: relative;
    padding: calc(2 * var(--basey)) calc(1 * var(--basex));

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--color_light_1);
    margin-bottom: calc(2 * var(--basey));
}

.card > div{
    width: 100%;
    display: flex;
    align-items: center;
}

.card > div:first-child{
    width: fit-content;
    position: absolute;
    top: calc(1.5 * var(--basey));
    right: calc(1.5 * var(--basex));
}
.card > div:not(:first-child,:last-child) {
    margin-bottom: calc(2 * var(--basey))
}
.card > div:not(:first-child) > div{
    width: 49%;
    margin-left: 1%;
}
@media screen and (max-width:700px) {
    .stats > div{
        flex-direction: column;
    }
    .stats > div > div {
        width: 100%;
    
    }
    .stats > div > div:not(:last-child) {
        width: 100%;
        margin-bottom: calc(1 * var(--basey));
    }

    .btns{
        flex-direction: column;
        width: 100%;
    }
    .btns > div, .btns > div:first-child {
        width: 100%;
    }
    .btns > div:last-child{
        justify-content: flex-end;
    }
    .btns > div:last-child > div:first-child{
        margin-left: 0;
    }
    .input{
        width: 100%;
        position: relative;
        margin-bottom: calc(2 * var(--basey));
    }
    .search svg{
        margin-left: 0%;

        position: absolute;
        right: 2%;
    }
}