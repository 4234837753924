.container{
    min-height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--color_dark_1);
}
.container > div{

}
.container > div:first-child{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: calc(5 * var(--basex));
    margin-top: calc(5 * var(--basex));
}
.container > div:first-child > svg{
    font-size: 4rem;
    color: var(--color_light_1);
}

.wrapper{
    display: flex;
    flex-direction: column;

    width:calc(35 * var(--basex));
}
.input{
    margin-bottom: 2rem;
}
.split{
    display: flex;
    justify-content: space-between;

    /* margin-bottom: 2rem; */
}
.split > div {
    width:47%;
}

.box{
    border-radius: 5px;
    border: 1px solid var(--color_dark_1);
    padding: calc(1 * var(--basey)) calc(1 * var(--basex));
}
.box:not(:last-child){
    margin-bottom: calc(2 * var(--basey));
}
.documentBox{
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--color_dark_1);
    padding: calc(1 * var(--basey)) calc(1 * var(--basex));
    margin-bottom:calc(1 * var(--basey));
}
.documentBox:not(:last-child){
    margin-bottom: calc(1 * var(--basey));
}
.documentBox svg{
    color: var(--color_dark_1);
}
.documentBox > div{
    display: flex;
    align-items: center;
}
.documentBox > div:nth-child(1){
    width: 90%;
}
.documentBox > div:nth-child(2) svg{
    font-size: 2rem;
}
.documentBox > div:nth-child(2){
    width: 10%;
}

.cta{
    display:flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color_light_1);
    
    width: 100%;
    height: calc(4.5 * var(--basey));

    margin-bottom: calc(5 * var(--basey));
}

@media only screen and (max-width:600px){
    .container > div:first-child{
        padding-left: calc(2 * var(--basex));
        margin-top: calc(2 * var(--basey));
    }
}