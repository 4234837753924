.container{
    height: calc(4.8* var(--basey));
    padding: 0 calc(1.7 * var(--basex));

    border: 2px solid var(--color_dark_1);
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color_dark_1);

    cursor: pointer;
}

.active{
    background-color: var(--color_dark_1);
    color: var(--color_light_1);
}