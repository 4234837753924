.input{
    width: 100%;
    padding: calc(.5 * var(--basey)) 0 calc(.5 * var(--basey)) calc(2.3 * var(--basex));
    border-radius: calc(.5 * var(--basex));
    height: calc(4.8* var(--basey));
}

.error{
    font-size: 1rem;
    color: var(--color_dark_1);
    margin-bottom: calc(1.782 * var(--basey));
}