.container{
    display: flex;
    align-items: center;

    padding: calc(1.6 * var(--basey)) 0;
    padding-left: calc(5.04 * var(--basex));  
    font-size: 1.92rem; 

    text-decoration: none;
    color: var(--color_dark_2);
}
.active{
    background-color: var(--color_dark_1);
    color: var(--color_light_1);
}
.icon{
    margin-right: calc(1.92 * var(--basex));
}
.icon svg{
    font-size: 3.2rem;
}