.container{
    width: 100%;
    height: calc(4.8* var(--basey));
    padding: 0 calc(1.7 * var(--basex));
    border: 2px solid var(--color_dark_1);
    border-radius: 5px;


    display: flex;
    align-items: center;
    position: relative;

}

.label{
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color_dark_1);

    cursor: pointer;
}
.label div{
    margin-left: calc(1 * var(--basex));
}
.label div svg{
    display: flex;
    justify-content: center;
    align-items: center;
}

.options{
    position: absolute;
    top:calc(100% + var(--basey));
    right: 0;
    background-color: var(--color_light_1);
    border-radius: 5px;
    min-width: 100%;
    z-index:10;
    border: 1px solid black;
}
.option{
    display: flex;
    align-items: center;
    
    padding:  calc(1 * var(--basey)) calc(1.7 * var(--basex)) calc(1 * var(--basey)) calc(.7 * var(--basex));
}

.option div{
    margin-left: calc(1 * var(--basex));
}